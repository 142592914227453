@use "variables" as *;
@use "mixins" as mix;

// ----------------
// Buttons
// ----------------
.primaryBtn {
    @include mix.primaryBtn($chevron: false, $arrow: true);

    @media (max-width: $breakpoint-sm) {
        &.vAll {
            &:after {
                margin: 0;
            }

            span {
                display: none;
            }
        }
    }
}

.secondaryBtn {
    @include mix.secondaryBtn($chevron: false);

    &.inverted {
        background: transparent;
        border: 2px solid $base-white;
        color: $base-white;

        svg {
            path {
                fill: $base-white;
            }
        }

        &:hover,
        &:focus,
        &:active {
            opacity: 0.8;
        }
    }

    &.black-shadow {
        box-shadow: 0px 7px 9px 0px rgba(0, 50, 92, 1);
    }
}

.tertiaryBtn {
    @include mix.tertiaryBtn($chevron: false, $arrow: true);

}

// Remove this styling when you start on new-project
// main {
//     height: 100vh;
// }
// .sf_logo {
//     width: 250px;
//     height: 100%;
// }

.z-index-1 {
    z-index: 1;
}

// .z-index-2 {
//     z-index: 2;
// }

.bg-white {
    .sub-banner {
        .wave {
            svg {
                path {
                    fill: $base-white;
                }
            }
        }
    }
}

.subbanner-education-bg {
    background-color: #f4f4f4;
}

.wave {
    svg {
        path {
            fill: $base-white;
        }
    }
}

.light-gray-wave {
    svg {
        path {
            &:nth-child(1) {
                fill: rgba(244, 244, 244, 1);
            }
        }
    }
}

.footer {
    &.rotate {
        @include mix.transform(rotate(-180deg));
    }

    &.bg-light-grey {
        svg {
            path {
                &:nth-child(2) {
                    fill: rgba(244, 244, 244, 1);
                }
            }
        }
    }

    &.bg-blue-wave {
        svg {
            path {
                &:nth-child(2) {
                    fill: $primary-color;
                }
            }
        }
    }

    &.wave-white {
        svg {
            path {
                &:nth-child(1) {
                    fill: $base-white
                }
            }
        }
    }

    &.wave-yellow {
        svg {
            path {
                &:nth-child(1) {
                    fill: $secondary-color;
                }
            }
        }
    }

    &.wave-blue {
        svg {
            path {
                &:nth-child(1) {
                    fill: $primary-color;
                }
            }
        }
    }


    &.white {
        svg {
            path {
                &:nth-child(2) {
                    fill: $base-white;
                }
            }
        }
    }

    &.yellow {
        svg {
            path {
                &:nth-child(2) {
                    fill: $secondary-color;
                }
            }
        }
    }
}

//Schools/Our Services
.cursive-arrow-1 {
    position: relative;

    &:before {
        content: "";
        background-image: url("/site-assets/svg/cursive-arrow-2.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 280px;
        min-height: 280px;
        position: absolute;
        top: -130px;
        left: 50%;
        z-index: 1;
        @include mix.transform(translateX(-50%));
    }

    @media (max-width: $breakpoint-lg) {
        // margin-top: 3rem;

        &:before {
            width: 240px;
            top: -110px;
        }
    }
}

// The national college page
.the-national-college-page {
    .overlay-img-1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
    }
}

// Tradewind careers page
.wave-with-bg-image {
    .top-wave {
        left: 0;
        right: 0;
        top: 0;
    }

    .image-wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            opacity: 0.6;
            object-fit: cover;

        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 1);
        }
    }

    @media (min-width: $breakpoint-xl) {
        .image-wrapper {
            img {
                object-position: 50% 290px;
            }
        }
    }
}

.primary-bg {
    background: $primary-color;
}

.bg-light-white {
    background: rgba(244, 244, 244, 1);

}

.sr-only {
    display: none;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $base-white;
}

.footer-edited {
    &.white {
        svg {
            path {
                &:nth-child(2) {
                    fill: #f4f4f4;
                }
            }
        }
    }
}