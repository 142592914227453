@import "styles/mixins";
@import "styles/variables";


.root {

    &:global.show-arrow.secondaryBtn,
    &:global.show-arrow.primaryBtn,
    &:global.show-arrow.tertiaryBtn {
        &:after {
            content: none
        }
    }

    &:global.defaultBtn {
        @include trans;

        span {
            gap: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover,
        &:focus,
        &:active {
            opacity: 0.5;
        }
    }
}