@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $primary-color;
        color: $base-white;
        position: relative;
        z-index: 1;
        top: -1px;

        .mobile-socials {
            max-width: 282px;
            margin: 0 auto;

            ul {
                margin: 0;
            }
        }

        .logo {
            @include trans;

            // svg {
            //     width: 100%;
            //     height: 100%;
            //     max-width: 240px;
            //     max-height: 88px;
            // }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .sourceflow {
            @include trans;

            svg {
                width: 100%;
                height: 100%;
                max-width: 137px;
                max-height: 15px;
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .copyright-wrapper {

            .copyrights {
                background: #00000033;
                border-radius: 10px;
                padding: 20px;
            }

            a {

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }

            p {
                font-size: 12px;
                line-height: 16.2px;
            }
        }

        .credits {
            img {
                max-height: 88px;
                max-width: 266px;
                width: auto;
                object-fit: contain;
                @include trans;

                &.youngminds {
                    max-width: 158px;
                }
            }

            a {

                &:hover,
                &:focus,
                &:active {
                    img {
                        opacity: 0.5;
                    }
                }
            }

        }

        .column {
            .h2 {
                font-size: 27px;
                line-height: 36.45px;
                padding: 0 0 0.5rem 0;
            }

            a {

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }

            &:nth-child(1) {
                min-width: 282px;
                text-align: center;
            }

            &:nth-child(2) {
                max-width: 610px;

                ul {
                    li {
                        a {
                            line-height: 21.6px;
                        }
                    }
                }
            }

            &:nth-child(3) {
                max-width: 215px;
            }
        }

        .heading {
            border-bottom: 3px solid $secondary-color;
        }

        @media (min-width: $breakpoint-lg) {
            .logo {
                svg {
                    width: 100%;
                    height: 100%;
                    max-width: 240px;
                    max-height: 88px;
                }
            }

            .column {

                &:nth-child(1) {
                    width: 20%;
                    text-align: left;
                }

                &:nth-child(3) {
                    width: 25%;
                }

                &:nth-child(2) {
                    width: 55%;
                    background: #0000001A;
                    border-radius: 20px;
                    padding: 20px;

                    ul {
                        gap: 16px;
                        @include flex;
                        @include flex-wrap;

                        li {
                            width: calc((100%/3) - 32px);
                        }
                    }
                }

                &:nth-child(3) {
                    padding: 20px 0 0 0;
                }
            }
        }

        @media (max-width: $breakpoint-lg) {
            .credits {
                li {
                    width: calc((100%/2) - 50px);
                    text-align: center;

                    img {
                        max-width: 100%;

                        &.rec {
                            min-height: 100px;
                        }

                        &.youngminds {
                            max-width: 70%;
                        }
                    }
                }
            }

            .copyright-wrapper {
                text-align: center;
            }

            .site-by,
            .copyright-links,
            .trademark {
                width: 100%;
            }

            .logo {
                // svg {
                //     max-width: 160px;
                // }
            }

            .column {
                &:nth-child(1) {
                    margin-bottom: 3rem;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    width: 100%;
                    overflow: hidden;
                    max-width: 320px;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                    cursor: pointer;
                    @include trans;

                    .heading {
                        display: flex;
                        justify-content: space-between;
                        gap: 30px;
                        @include trans;

                        &:hover,
                        &:focus,
                        &:active {
                            opacity: 0.5;
                        }

                        &:after {
                            content: "";
                            margin-top: 6px;
                            width: 26px;
                            height: 26px;
                            display: block;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-image: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.0001 0.258397C9.60528 0.258397 6.34955 1.60696 3.94908 4.00743C1.54862 6.40789 0.20005 9.66363 0.20005 13.0584C0.200049 16.4532 1.54862 19.7089 3.94908 22.1094C6.34955 24.5098 9.60528 25.8584 13 25.8584C16.3948 25.8584 19.6506 24.5098 22.051 22.1094C24.4515 19.7089 25.8 16.4532 25.8 13.0584C25.8001 9.66363 24.4515 6.4079 22.051 4.00743C19.6506 1.60697 16.3948 0.258397 13.0001 0.258397ZM14.2001 7.8584C14.2001 7.54014 14.0736 7.23491 13.8486 7.00987C13.6235 6.78483 13.3183 6.6584 13.0001 6.6584C12.6818 6.6584 12.3766 6.78483 12.1515 7.00987C11.9265 7.23491 11.8001 7.54014 11.8001 7.8584L11.8 15.2024L8.68005 11.8424C8.46363 11.609 8.16337 11.4711 7.8453 11.4591C7.52724 11.4471 7.21744 11.562 6.98405 11.7784C6.75066 11.9948 6.6128 12.2951 6.60079 12.6131C6.58879 12.9312 6.70363 13.241 6.92005 13.4744L12.12 19.0744C12.2324 19.1956 12.3685 19.2922 12.52 19.3583C12.6714 19.4244 12.8348 19.4586 13 19.4586C13.1653 19.4586 13.3287 19.4244 13.4801 19.3583C13.6316 19.2922 13.7677 19.1956 13.88 19.0744L19.0801 13.4744C19.1872 13.3588 19.2706 13.2233 19.3253 13.0755C19.3801 12.9277 19.4052 12.7706 19.3993 12.6131C19.3934 12.4557 19.3565 12.3009 19.2907 12.1577C19.2249 12.0144 19.1316 11.8856 19.0161 11.7784C18.9005 11.6712 18.7649 11.5879 18.6172 11.5331C18.4694 11.4783 18.3123 11.4532 18.1548 11.4591C17.9973 11.4651 17.8425 11.502 17.6993 11.5677C17.5561 11.6335 17.4272 11.7268 17.32 11.8424L14.2 15.2024L14.2001 7.8584Z" fill="%23FFB81C"/></svg>');

                        }
                    }

                    ul {
                        margin: 0;
                        height: 0;

                        &.active {
                            height: 100%;
                        }

                        li {
                            margin-bottom: 1rem;

                        }
                    }
                }
            }
        }
    }
}