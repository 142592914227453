@use "variables" as *;

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-15px);
    }

    50% {
        transform: translateY(0);
    }

    57% {
        transform: translateY(-5px);
    }

    64% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes expand-hor {
    0% {
        width: 0;
    }

    100% {
        width: calc(102% + 0.8rem);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slide-up {
    0% {
        transform: translate3d(0, 30px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes minus-top {
    0% {
        transform: translate3d(0, -2rem, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, -4rem, 0);
        opacity: 1;
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}