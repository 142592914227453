@use "styles/variables" as *;
@use "styles/mixins" as mix;

$brand-color: $primary-color;
$text-color: $base-white;
$spacer: 1rem;
$font-size: small;
$z-index: 1099;
$overlay-color: rgba($base-black, 0.75);

.cookieConsent__buttonWrapper {
  margin-top: 1rem;
}

.cookieConsent {
  // opacity: 0;
  // display: flex;
  width: 100%;
  position: fixed;
  // flex-wrap: wrap;
  // gap: $spacer;
  bottom: 0;
  color: $text-color;
  background-color: $brand-color;
  font-size: $font-size;
  z-index: $z-index;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // justify-content: center;
  text-align: center;
  @include mix.trans;

  @media (max-width: $breakpoint-lg) {
    font-size: 10px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-color: $overlay-color;
    z-index: $z-index;
  }

  &__button {
    @include mix.primaryBtn($chevron: false, $arrow: true);
    font-size: $font-size;
    white-space: nowrap;
    margin: 0;
    border: 0;
    width: calc(100% / 2);

    display: flex;
    justify-content: center;
    text-align: center;

    // $chevron: true, $arrow: false

    &Wrapper {
      display: flex;
      gap: $spacer;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $breakpoint-lg) {
        max-width: 300px;
        margin: 1rem auto 0 auto;
        display: flex;
        flex-direction: row;
      }
    }

    &Decline {
      background-color: transparent;
      text-decoration: underline;
      font-size: $font-size;
      color: white;
      white-space: nowrap;
      justify-content: center;
      margin: 2px;
      border: 0;
      width: calc(100%/2);
    }
  }
}
