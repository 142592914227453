@import "styles/mixins";
@import "styles/variables";

.root {
  :global {
    .js-container {
      position: relative;
      z-index: 1;
      font-family: $font-family;

      .js-form {
        padding: 0.885rem 1rem 0.885rem 4.5rem;
        background: $base-white;
        border-radius: 40px;
        box-shadow: 0px 4px 4px 0px rgba(0, 67, 124, 0.7);

        .js-input-query {
          border: none;
          padding: 0;
          border-radius: 0;
          outline: none;
          font-weight: 400;
          line-height: 21.6px;
          font-size: 16px;
          height: auto;
        }

        .js-input-wrapper {
          position: relative;
          padding-right: 3rem;
          width: 100%;

          &:before {
            content: "";
            background-image: url("/site-assets/svg/search.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            left: -40px;
          }
        }

        .js-input-location {
          border: none;
          padding: 0;
          border-radius: 0;
          outline: none;
          font-weight: 400;
          line-height: 21.6px;
          font-size: 16px;
          height: auto;
        }

        .js-input-wrapper.js-input-wrapper-location {
          position: relative;
          padding-right: 3rem;
          width: 100%;

          &:before {
            display: block;
            position: absolute;
            left: -3rem;
            width: 1px;
            height: 2rem;
            background-color: $primary-color;
          }
        }

        .js-form-input-wrapper {
          align-items: center;
          justify-content: space-between;
        }

        .js-button {
          white-space: nowrap;
          @include primaryBtn($chevron: false, $arrow: true);
          margin: 0;
          flex-grow: inherit;
        }
      }
    }

    @media (max-width: $breakpoint-lg) {
      .js-container {
        .js-form {
          background: transparent;
          padding: 0;
          border: none;
          box-shadow: none;

          .js-input-wrapper {
            padding: 0;
            background: $base-white;
            padding: 10px 20px 10px 60px;
            width: 100%;
            border-radius: 45px;
            margin-bottom: 1rem;
            box-shadow: 0px 7px 9px 0px rgba(0, 50, 92, 1);

            &:before {
              left: 20px;
            }
          }
          .js-input-wrapper.js-input-wrapper-location {
            padding: 0;
            background: $base-white;
            padding: 10px 20px 10px 60px;
            width: 100%;
            border-radius: 45px;
            margin-bottom: 1rem;
            margin-left: 0 !important;
            box-shadow: 0px 7px 9px 0px rgba(0, 50, 92, 1);

            &:before {
              background-image: url("/site-assets/svg/search.svg");
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              width: 24px;
              height: 24px;
              position: absolute;
              left: 20px;
              background-color: transparent;
            }
          }

          .js-form-input-wrapper {
            @include flex-wrap;
          }

          .js-button {
            box-shadow: 0px 7px 9px 0px rgba(0, 50, 92, 1);
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
