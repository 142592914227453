@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        color: $base-white;

        a {
            @include trans;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }

        @media (max-width: $breakpoint-md) {
            max-width: 75%;
            margin: 0 auto;
        }
    }

    &:global.blog-style {
        background: $secondary-color;
        padding: 20px !important;
        border-radius: 6px;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        color: $brown-color;
        justify-content: center;
        gap: 10px;
        width: 100%;
        flex-wrap: wrap;

        svg {
            rect {
                display: none;
            }

            path {
                fill: $brown-color;
            }
        }

        ul {
            gap: 0px !important;
            max-width: 100%;
            margin: 0;
            width: 100%;

        }

        @media (min-width: $breakpoint-lg) {
            width: auto;

            ul {
                flex-wrap: nowrap !important;
                max-width: 250px;
            }
        }

        @media (max-width: $breakpoint-lg) {
            max-width: 100%;
            text-align: center;

            ul {
                max-width: 250px;
            }
        }
    }
}