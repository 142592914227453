@use "variables" as *;

// Typography
html {
    font-size: 100%;
}

body {
    font-family: $font-family;
    font-weight: 400;
    line-height: 1.75;
    color: $font-color;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    margin-bottom: 1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-weight: 700;
}

h1,
.h1 {
    margin-top: 0;
    font-size: 80px;

    @media (max-width: $breakpoint-lg) {
        font-size: 61px;
    }
}

h2,
.h2 {
    font-size: 61px;

}

h3,
.h3 {
    font-size: 47px;

    @media (max-width: $breakpoint-lg) {
        font-size: 36px;
    }
}

h4,
.h4 {
    font-size: 24px;
}

h5,
.h5 {
    font-size: 1.333rem;
}

small,
.text_small {
    font-size: 0.8rem;
}

.text-white {

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    * {
        color: $base-white;
    }
}